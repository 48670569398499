import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import api from "../axiosInstance";
import {
  CreateCompensationModelHistoryRequestDTO,
  CreateCompensationModelHistoryResponseDTO,
  GetCompensationModelHistoryResponseDTO,
  GetCompensationResponseDTO,
} from "./compensation.dto";
import { useNotification } from "../../hooks/useNotification";
import { ErrorResponse } from "../../types/errorResponse.type";

const ENDPOINT = "/compensation";

export enum CompensationQueryKeys {
  GetTotalCompensation = "get-total-compensation",
  GetDistributorsCompensation = "get-distributors-compensation",
  GetInstallersCompensation = "get-installers-compensation",
  GetDistributorCompensation = "get-distributor-compensation",
  GetInstallerCompensation = "get-installer-compensation",
  GetCompensationModelHistory = "get-compensation-model-history",
  GetCurrentCompensationModel = "get-current-compensation-model",
}

export const useTotalCompensation = () => {
  return useQuery({
    queryKey: [CompensationQueryKeys.GetTotalCompensation],
    queryFn: async (): Promise<GetCompensationResponseDTO[]> =>
      (await api.get(`${ENDPOINT}`)).data,
  });
};

export const useDistributorsCompensation = () => {
  return useQuery({
    queryKey: [CompensationQueryKeys.GetDistributorsCompensation],
    queryFn: async (): Promise<GetCompensationResponseDTO[]> =>
      (await api.get(`${ENDPOINT}/distributor`)).data,
  });
};

export const useInstallersCompensation = () => {
  return useQuery({
    queryKey: [CompensationQueryKeys.GetInstallersCompensation],
    queryFn: async (): Promise<GetCompensationResponseDTO[]> =>
      (await api.get(`${ENDPOINT}/installer`)).data,
  });
};

export const useDistributorCompensation = (distributorId?: number) => {
  return useQuery({
    queryKey: [CompensationQueryKeys.GetDistributorCompensation],
    queryFn: async (): Promise<GetCompensationResponseDTO[]> =>
      (await api.get(`${ENDPOINT}/distributor/${distributorId}`)).data,
    enabled: !!distributorId,
  });
};

export const useInstallerCompensation = (installerId?: number) => {
  return useQuery({
    queryKey: [CompensationQueryKeys.GetInstallerCompensation],
    queryFn: async (): Promise<GetCompensationResponseDTO[]> =>
      (await api.get(`${ENDPOINT}/installer/${installerId}`)).data,
    enabled: !!installerId,
  });
};

export const useCompensationModelHistory = () => {
  return useQuery({
    queryKey: [CompensationQueryKeys.GetCompensationModelHistory],
    queryFn: async (): Promise<GetCompensationModelHistoryResponseDTO[]> =>
      (await api.get(`${ENDPOINT}/model`)).data,
  });
};

export const useCurrentCompensationModelPeriod = () => {
  return useQuery({
    queryKey: [CompensationQueryKeys.GetCurrentCompensationModel],
    queryFn: async (): Promise<GetCompensationModelHistoryResponseDTO> =>
      (await api.get(`${ENDPOINT}/model/current`)).data,
  });
};

export const useCreateCompensationModelPeriod = (options = {}) => {
  const notification = useNotification();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
      payload: CreateCompensationModelHistoryRequestDTO,
    ): Promise<CreateCompensationModelHistoryResponseDTO> =>
      (await api.post(`${ENDPOINT}/model`, payload)).data,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [CompensationQueryKeys.GetCompensationModelHistory],
      });
      queryClient.invalidateQueries({
        queryKey: [CompensationQueryKeys.GetCurrentCompensationModel],
      });
      notification.info(`Compensation model period created`);
    },
    onError: (error: ErrorResponse) => {
      notification.error(error.response.data.message);
    },
    ...options,
  });
};

export const useDeleteCompensationModelPeriod = (settings = {}) => {
  const notification = useNotification();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (id: number): Promise<void> => {
      await api.delete(`${ENDPOINT}/model/${id}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [CompensationQueryKeys.GetCompensationModelHistory],
      });
      queryClient.invalidateQueries({
        queryKey: [CompensationQueryKeys.GetCurrentCompensationModel],
      });
      notification.info(`Compensation period deleted`);
    },
    onError: (error: ErrorResponse) => {
      notification.error(error.response.data.message);
    },
    ...settings,
  });
};
