import { sub } from "date-fns";
import { GetCompensationResponseDTO } from "../../api/compensation/compensation.dto";

export const getTotalCompensation = (
  compensationResponse: GetCompensationResponseDTO[],
): string => {
  return compensationResponse
    .reduce(
      (yearlyAcc, yearlyValue) =>
        yearlyAcc +
        yearlyValue.compensations.reduce(
          (monthlyAcc, monthlyValue) => monthlyAcc + monthlyValue.compensation,
          0,
        ),
      0,
    )
    .toLocaleString("sv-SE");
};

export const getPreviousYearCompensation = (
  compensationResponse: GetCompensationResponseDTO[],
): string | number => {
  const currentYearCompensation = compensationResponse.find(
    (item) => item.year === new Date().getFullYear() - 1,
  );

  if (!currentYearCompensation) {
    return 0;
  } else {
    return currentYearCompensation.compensations
      .reduce(
        (monthlyAcc, monthlyValue) => monthlyAcc + monthlyValue.compensation,
        0,
      )
      .toLocaleString("sv-SE");
  }
};

export const getCurrentYearCompensation = (
  compensationResponse: GetCompensationResponseDTO[],
): string | number => {
  const currentYearCompensation = compensationResponse.find(
    (item) => item.year === new Date().getFullYear(),
  );

  if (!currentYearCompensation) {
    return 0;
  } else {
    return currentYearCompensation.compensations
      .reduce(
        (monthlyAcc, monthlyValue) => monthlyAcc + monthlyValue.compensation,
        0,
      )
      .toLocaleString("sv-SE");
  }
};

export const getPreviousMonthCompensation = (
  compensationRes: GetCompensationResponseDTO[],
): string | number => {
  const previousMonthCompensation = compensationRes.find(
    (item) =>
      item.year ===
      sub(new Date(), {
        months: 1,
      }).getFullYear(),
  );

  if (!previousMonthCompensation) {
    return 0;
  } else {
    return previousMonthCompensation.compensations[
      sub(new Date(), {
        months: 1,
      }).getMonth()
    ].compensation.toLocaleString("sv-SE");
  }
};
