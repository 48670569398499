import { TabPageWrapper } from "../../../components/tab/TabPageWrapper";
import TabContainer from "../../../components/tab/TabContainer";
import { CompensationModelTab } from "../../../components/compensation/CompensationModelTab";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { BreadCrumb, menuStore } from "../../../store/menu.store";
import { useEffect } from "react";
import { RouteLabel } from "../../../routing/routeLabel.enum";
import { RouteUrl } from "../../../routing/routeUrl.enum";
import BillingAdjustmentTab from "../../../components/billingAdjustment/BillingAdjustmentTab";
import { CompensationStatisticsTab } from "../../../components/compensation/CompensationStatisticsTab";

const MONTHLY_BILLING_ADJUSTMENT_TAB = "monthly-billing-adjustment";
const COMPENSATION_MODEL = "compensation-model";
const COMPENSATION_STATISTICS = "compensation-statistics";

export const CompensationPage = () => {
  const { t } = useTranslation();

  // Tab
  const [searchParams, setSearchParams] = useSearchParams();
  const setTab = (tab: string) => setSearchParams({ tab: tab.toLowerCase() });

  // Breadcrumbs
  const { setBreadCrumbs } = menuStore();
  useEffect(() => {
    setBreadCrumbs(getBreadCrumbs());
  }, []);

  const currentTab = searchParams.get("tab") || MONTHLY_BILLING_ADJUSTMENT_TAB;

  return (
    <TabPageWrapper
      tabs={[
        {
          param: MONTHLY_BILLING_ADJUSTMENT_TAB,
          label: t(`compensation.MonthlyBillingAdjustment`),
          content: (
            <TabContainer key={MONTHLY_BILLING_ADJUSTMENT_TAB}>
              <BillingAdjustmentTab />
            </TabContainer>
          ),
          isSelected: currentTab === MONTHLY_BILLING_ADJUSTMENT_TAB,
        },
        {
          param: COMPENSATION_MODEL,
          label: t(`compensation.CompensationModel`),
          content: (
            <TabContainer key={COMPENSATION_MODEL}>
              <CompensationModelTab />
            </TabContainer>
          ),
          isSelected: currentTab === COMPENSATION_MODEL,
        },
        {
          param: COMPENSATION_STATISTICS,
          label: t(`compensation.CompensationStatistics`),
          content: (
            <TabContainer key={COMPENSATION_STATISTICS}>
              <CompensationStatisticsTab />
            </TabContainer>
          ),
          isSelected: currentTab === COMPENSATION_STATISTICS,
        },
      ]}
      setTab={setTab}
    />
  );
};

const getBreadCrumbs = (): BreadCrumb[] => [
  {
    label: RouteLabel.Compensation,
    to: `${RouteUrl.App}/${RouteUrl.Compensation}`,
  },
];
