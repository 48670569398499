import Modal from "components/Modal";
import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AddCompensationModelPeriodForm } from "./AddCompensationModelPeriodForm";
import SecondaryButton from "components/buttons/SecondaryButton";
import { useTranslation } from "react-i18next";
import { useCreateCompensationModelPeriod } from "../../api/compensation/compensation.endpoints";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { CreateCompensationModelHistoryRequestDTO } from "../../api/compensation/compensation.dto";

export const AddCompensationModelPeriod: FC = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  // api
  const { mutate, isPending, isSuccess } = useCreateCompensationModelPeriod();

  // form
  const {
    register,
    control,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm<CreateCompensationModelHistoryRequestDTO>();

  // submit
  const onSubmit = handleSubmit((data) => {
    mutate(data);
  });

  // close modal
  const closeModal = () => {
    setIsModalOpen(false);
    setTimeout(() => reset(), 300);
  };

  // trigger on successful add
  useEffect(() => {
    if (isSuccess) {
      closeModal();
    }
  }, [isSuccess]);

  return (
    <>
      <div className="flex justify-end">
        <SecondaryButton onClick={() => setIsModalOpen(true)}>
          <PlusCircleIcon className="h-5 w-5 mr-1" />
          {t("compensation.AddCompensationPeriod")}
        </SecondaryButton>
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        leftButtonAction={closeModal}
        leftButtonText={t(`Close`)}
        title={t("compensation.AddCompensationPeriod")}
        rightButtonActionHandler={onSubmit}
        isLoading={isPending}
        rightButtonText={t("Add")}
      >
        <AddCompensationModelPeriodForm
          register={register}
          control={control}
          getValues={getValues}
          errors={errors}
        />
      </Modal>
    </>
  );
};
