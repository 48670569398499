import api from "../axiosInstance";
import {
  CreatePrequalificationGroupDTO,
  PrequalificationGroupDTO,
} from "./flowerSettings.dto";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

export const ENDPOINT = "/flower-settings";

export enum FlowerSettingsQueryKeys {
  GetPrequalificationGroup = "prequalification-groups-get",
}

export const usePrequalificationGroup = () =>
  useQuery({
    queryKey: [FlowerSettingsQueryKeys.GetPrequalificationGroup],
    queryFn: async (): Promise<PrequalificationGroupDTO[]> =>
      (await api.get(`${ENDPOINT}/prequalification-group`)).data,
  });

export const useCreatePrequalificationGroup = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
      payload: CreatePrequalificationGroupDTO,
    ): Promise<PrequalificationGroupDTO> =>
      (await api.post(`${ENDPOINT}/prequalification-group`, payload)).data,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [FlowerSettingsQueryKeys.GetPrequalificationGroup],
      }),
  });
};

export const useUpdatePrequalificationGroup = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
      payload: PrequalificationGroupDTO,
    ): Promise<PrequalificationGroupDTO> =>
      (
        await api.put(
          `${ENDPOINT}/prequalification-group/${payload.id}`,
          payload,
        )
      ).data,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [FlowerSettingsQueryKeys.GetPrequalificationGroup],
      }),
  });
};
