import { useTranslation } from "react-i18next";
import {
  useCompensationModelHistory,
  useCurrentCompensationModelPeriod,
} from "../../api/compensation/compensation.endpoints";
import React, { useMemo } from "react";
import { CompensationStatistics, Tile } from "./CompensationStatistics";
import { TableOptions } from "@tanstack/react-table";
import { DeleteCompensationModel } from "./DeleteCompensationModel";
import CustomPlaceholder from "../CustomPlaceholder";
import { Cog6ToothIcon } from "@heroicons/react/16/solid";
import { BanknotesIcon } from "@heroicons/react/24/outline";
import { AddCompensationModelPeriod } from "./AddCompensationModelPeriod";
import { TanStackTable } from "../table/TanStackTable";
import { GetCompensationModelHistoryResponseDTO } from "../../api/compensation/compensation.dto";
import moment from "moment";

export const CompensationModelTab = () => {
  const { t } = useTranslation();

  const {
    data: currentCompensationModel,
    isLoading: isLoadingCurrentCompensationModel,
    isError: isErrorCurrentCompensationModel,
  } = useCurrentCompensationModelPeriod();

  const {
    data: compensationModelHistory,
    isLoading: isLoadingCompensationModelHistory,
    isError: isErrorCompensationModelHistory,
  } = useCompensationModelHistory();

  const isLoading =
    isLoadingCurrentCompensationModel || isLoadingCompensationModelHistory;
  const isError =
    isErrorCurrentCompensationModel || isErrorCompensationModelHistory;

  const currentCompensationTiles: Tile[] = useMemo(
    () =>
      currentCompensationModel
        ? [
            {
              title: t("compensationModel.asset owner"),
              value: `${currentCompensationModel.assetOwnerCompensation} ${t("compensationModel.compensation unit")}`,
            },
            {
              title: t("compensationModel.installer"),
              value: `${currentCompensationModel.installerCompensation} ${t("compensationModel.compensation unit")}`,
            },
            {
              title: t("compensationModel.distributor"),
              value: `${currentCompensationModel.distributorCompensation} ${t("compensationModel.compensation unit")}`,
            },
          ]
        : [],
    [currentCompensationModel],
  );

  const columns: TableOptions<GetCompensationModelHistoryResponseDTO>["columns"] =
    useMemo(
      () => [
        {
          header: t("From"),
          accessorKey: "startDate",
          accessorFn: (row) => moment.utc(row?.startDate).format("DD MMM YYYY"),
        },
        {
          header: t("To"),
          accessorKey: "endDate",
          accessorFn: (row) => moment.utc(row?.endDate).format("DD MMM YYYY"),
        },
        {
          header: t("compensationModel.asset owner"),
          accessorKey: "assetOwnerCompensation",
          accessorFn: (row) => {
            return `${row?.assetOwnerCompensation} ${t("compensationModel.compensation unit")}`;
          },
        },
        {
          header: t("compensationModel.installer"),
          accessorKey: "installerCompensation",
          accessorFn: (row) => {
            return `${row?.installerCompensation} ${t("compensationModel.compensation unit")}`;
          },
        },
        {
          header: t("compensationModel.distributor"),
          accessorKey: "distributorCompensation",
          accessorFn: (row) => {
            return `${row?.distributorCompensation} ${t("compensationModel.compensation unit")}`;
          },
        },
        {
          header: "",
          accessorKey: "action",
          accessorFn: (row) => row,
          cell: (row) => {
            const data: GetCompensationModelHistoryResponseDTO = row.getValue();
            if (new Date(data.startDate) < new Date()) {
              return <div className="w-4 h-5 justify-center flex"></div>;
            }
            return (
              <DeleteCompensationModel compensationModelPeriodId={data.id} />
            );
          },
        },
      ],
      [compensationModelHistory],
    );

  if (isLoading || isError) {
    return (
      <div className="my-8">
        <CustomPlaceholder
          isError={isError}
          isLoading={isLoading}
          isEmpty={false}
          icon={<Cog6ToothIcon />}
          errorMessage={t(`compensationModel.error`)}
          emptyDataMessage={t(`compensationModel.empty`)}
          loaderSize="page"
        />
      </div>
    );
  }

  return (
    <div className="overflow-auto">
      <CompensationStatistics
        title={t("compensationModel.Current compensation")}
        icon={<BanknotesIcon />}
        tiles={currentCompensationTiles}
        emptyDataMessage={t("compensationModel.NotFound")}
        warmingMessage={
          currentCompensationModel ? "" : t("compensationModel.WarningMessage")
        }
      />

      <div className="flex flex-col">
        <div className="relative top-11 w-fit min-w-60 rounded flex justify-between items-end">
          <AddCompensationModelPeriod />
        </div>
        <div className="flex flex-col w-full overflow-hidden">
          <TanStackTable
            columns={columns}
            data={compensationModelHistory ? compensationModelHistory : []}
            filters={[]}
          />
        </div>
      </div>
    </div>
  );
};
