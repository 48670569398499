import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { classNames } from "helpers/classNames";
import { FC } from "react";
import {
  Control,
  Controller,
  RegisterOptions,
  FieldValues,
} from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface Props {
  label: string;
  name: string;
  validation?: Pick<
    RegisterOptions<FieldValues>,
    "maxLength" | "minLength" | "validate" | "required"
  >;
  //[LINT_TODO] Type casting with generic Filed value not working here. Do more research and make a solution.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control?: Control<any>;
  minDate?: Date;
  maxDate?: Date;
  showTimeSelect?: boolean;
}

export const DateTimePicker: FC<Props> = ({
  label,
  name,
  validation,
  control,
  minDate = undefined,
  maxDate = undefined,
  showTimeSelect = true,
}) => {
  return (
    <div>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className="mt-1 relative rounded-md">
        <Controller
          control={control}
          name={name}
          rules={validation}
          render={({ field, fieldState }) => (
            <>
              <DatePicker
                onChange={(date) => field.onChange(date)}
                selected={field.value}
                toggleCalendarOnIconClick
                preventOpenOnFocus
                showIcon
                icon={
                  <svg
                    className="w-4 h-5 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                  </svg>
                }
                showTimeSelect={showTimeSelect}
                dateFormat={
                  showTimeSelect ? "MMMM d, yyyy h:mm" : "MMMM d, yyyy"
                }
                minDate={minDate}
                maxDate={maxDate}
                className={classNames(
                  fieldState.error
                    ? "border-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500 pr-10 text-red-900 placeholder-red-300 rounded-md shadow-sm !pl-8"
                    : "border-gray-300 focus:border-flowerblue-500 focus:ring-flowerblue-500 rounded-md shadow-sm !pl-8",
                )}
              />
              {fieldState.error && (
                <div className="flex flex-row items-center mt-2">
                  <ExclamationCircleIcon
                    className="h-5 w-5 text-red-500"
                    aria-hidden="true"
                  />
                  <p className="ml-1 text-sm text-red-600">
                    {fieldState.error.message}
                  </p>
                </div>
              )}
            </>
          )}
        />
      </div>
    </div>
  );
};
