import { classNames } from "helpers/classNames";
import { UseFormRegister } from "react-hook-form";

export interface CheckboxItem {
  label: string;
  name: string;
}
interface CheckboxProps {
  item: CheckboxItem;
  //[LINT_TODO] Type casting with generic Filed value not working here. Do more research and make a solution.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>;
  textSize?: string;
}
export const Checkbox = ({
  item,
  register,
  textSize = "text-sm",
}: CheckboxProps) => (
  <div className="flex items-start">
    <div className="flex items-center h-5">
      <input
        id={item.name}
        type="checkbox"
        {...register(item.name)}
        className="focus:ring-flowerSky-400 h-4 w-4 text-flowerSky-600 border-gray-300 rounded cursor-pointer"
      />
    </div>
    <div className={classNames("ml-3", textSize)}>
      <label htmlFor={item.name} className="font-medium text-gray-700">
        {item.label}
      </label>
    </div>
  </div>
);
