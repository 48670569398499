import { create } from "zustand";
import { persist } from "zustand/middleware";

export const menuStore = create<MenuState>()(
  persist(
    (set) => ({
      breadCrumbs: [],
      setBreadCrumbs: (breadCrumbs: BreadCrumb[]) =>
        set((state) => ({
          ...state,
          breadCrumbs,
        })),
    }),
    {
      name: "menu-store",
    },
  ),
);

interface MenuState {
  breadCrumbs: BreadCrumb[];
  setBreadCrumbs: (list: BreadCrumb[]) => void;
}

export interface BreadCrumb {
  label: string;
  to: string;
  iconType?: BreadCrumbIconType;
}

export type BreadCrumbIconType = "distributor" | "installer" | "assetOwner";
