import { FC } from "react";
import { CustomSvgComponentProps } from "./FlowerHubIcon";

const ConfirmedIcon: FC<CustomSvgComponentProps> = ({ color = "black" }) => {
  return (
    <svg
      width="106"
      height="106"
      viewBox="0 0 106 106"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M59.8 14V32.9H78.7" stroke={color} strokeMiterlimit="10" />
      <path
        d="M78.7 32.9V92H25.1V14H59.8L78.7 32.9Z"
        stroke={color}
        strokeMiterlimit="10"
      />
      <path d="M85.4 56.7H59.8V82.3H85.4V56.7Z" fill={color} />
      <path
        d="M65 70.1L69.7 74.8L80.2 64.2"
        stroke="white"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default ConfirmedIcon;
