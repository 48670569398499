import { ModalContentWrapper } from "components/ModalContentWrapper";
import { TextInput } from "components/input/TextInput";
import { naturalNumberValidator } from "helpers/validation";
import { FC } from "react";
import { Control, FieldErrorsImpl, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { MonthTimePicker } from "../input/MonthDatePicker";
import { CreateCompensationModelHistoryRequestDTO } from "../../api/compensation/compensation.dto";
import moment from "moment/moment";

interface Props {
  register: UseFormRegister<CreateCompensationModelHistoryRequestDTO>;
  control: Control<CreateCompensationModelHistoryRequestDTO>;
  getValues: (
    payload?: string | string[],
  ) => CreateCompensationModelHistoryRequestDTO;
  errors: Partial<FieldErrorsImpl<CreateCompensationModelHistoryRequestDTO>>;
}

export const AddCompensationModelPeriodForm: FC<Props> = ({
  register,
  control,
  getValues,
  errors,
}) => {
  const { t } = useTranslation();

  return (
    <ModalContentWrapper>
      <TextInput
        label={t(`compensationModel.asset owner compensation`)}
        name="assetOwnerCompensation"
        register={register}
        registerOptions={naturalNumberValidator}
        errorMessage={errors.assetOwnerCompensation?.message}
        type="number"
        appendix={t(`compensationModel.compensation unit`)}
      />
      <TextInput
        label={t(`compensationModel.installer compensation`)}
        name="installerCompensation"
        register={register}
        registerOptions={naturalNumberValidator}
        errorMessage={errors.installerCompensation?.message}
        type="number"
        appendix={t(`compensationModel.compensation unit`)}
      />
      <TextInput
        label={t(`compensationModel.distributor compensation`)}
        name="distributorCompensation"
        register={register}
        registerOptions={naturalNumberValidator}
        errorMessage={errors.distributorCompensation?.message}
        type="number"
        appendix={t(`compensationModel.compensation unit`)}
      />
      <MonthTimePicker
        label={t("From")}
        name="startDate"
        control={control}
        validation={{
          required: t(`compensation.DateIsRequired`),
        }}
      />
      <MonthTimePicker
        label={t("To")}
        name="endDate"
        control={control}
        getEndOfTheMonth={true}
        validation={{
          required: t(`compensation.DateIsRequired`),
          validate: (value) => {
            const obj = getValues();
            return (
              moment.utc(new Date(value)) >
                moment.utc(new Date(obj.startDate)) ||
              t(`compensation.EndDateMustBeAfterStartDate`)
            );
          },
        }}
      />
    </ModalContentWrapper>
  );
};
