import { LoginPage } from "../pages/login/LoginPage";
import { Navigate, Outlet } from "react-router-dom";
import { AppWrapper } from "../components/appWrapper/AppWrapper";
import { RouteUrl } from "./routeUrl.enum";
import { DistributorsPage } from "pages/app/distributorList/DistributorListPage";
import DistributorPage from "pages/app/distributor/DistributorPage";
import SignUpDistributorPage from "pages/sign-up/SignUpDistributorPage";
import InstallerPage from "pages/app/installer/InstallerPage";
import { InstallerListPage } from "pages/app/installerList/InstallerListPage";
import { AssetOwnerListPage } from "pages/app/assetOwnerList/AssetOwnerListPage";
import ForgotPasswordPage from "pages/forgot-password/ForgotPasswordPage";
import ResetPasswordPage from "pages/reset-password/ResetPasswordPage";
import SignUpInstallerPage from "pages/sign-up/SignUpInstallerPage";
import { SignUpFlowPage } from "pages/sign-up-flow/SignUpFlowPage";
import { Role } from "enums/role.enum";
import { SignUpFlowerPage } from "pages/sign-up/SignUpFlowerPage";
import { DashboardPage } from "pages/app/dashboard/DashboardPage";
import { FlowerOrganizationPage } from "pages/app/flowerOrganization/FlowerOrganizationPage";
import { FlowerSettingsPage } from "pages/app/flowerSettings/FlowerSettingsPage";
import { DistributorOrganizationPage } from "pages/app/distributor/DistributorOrganizationPage";
import { ProfilePage } from "pages/app/assetOwner/ProfilePage";
import { NotFoundPage } from "pages/app/notFound/NotFoundPage";
import { ManualActionPage } from "pages/app/manualAction/ManualActionPage";
import { AssetListPage } from "pages/app/assetList/AssetListPage";
import { Centered } from "components/Centered";
import { Maintenance } from "pages/maintenance/maintenance";
import { SystemNotification } from "api/system-notification/systemNotification.type";
import { InvoicesPage } from "pages/app/assetOwner/InvoicesPage";
import { ConsumptionAndRevenuePage } from "pages/app/assetOwner/ConsumptionAndRevenuePage";
import { BatteryPage } from "pages/app/assetOwner/BatteryPage";
import { AssetOwnerDashboardPage } from "pages/app/assetOwner/AssetOwnerDashboardPage";
import AssetOwnerPage from "pages/app/assetOwnerPage/AssetOwnerPage";
import { InstallerOrganizationPage } from "pages/app/installer/InstallerOrganizationPage";
import {
  isAssetOwner,
  isDistributor,
  isFlower,
  isGuest,
  isInstaller,
} from "../helpers/role";
import { ScrollablePage } from "../components/appWrapper/ScrollablePage";
import { NotScrollablePage } from "../components/appWrapper/NotScrollablePage";
import { CompensationPage } from "../pages/app/compensation/CompensationPage";

export const maintenanceRoute = (maintenance?: SystemNotification) => [
  {
    path: RouteUrl.AllRoutes,
    element: <Navigate to={RouteUrl.Home} />,
  },
  {
    path: RouteUrl.Home,
    element: (
      <Centered>
        <Maintenance maintenance={maintenance} />
      </Centered>
    ),
  },
];

const routes = (isLoggedIn: boolean, userRole: Role) => [
  {
    path: RouteUrl.App + "/*",
    element: isLoggedIn ? (
      <AppWrapper>
        <Outlet />
      </AppWrapper>
    ) : (
      <Navigate to={RouteUrl.Login} />
    ),
    children: [
      ...getAppRoutes(userRole),
      {
        path: "*",
        element: (
          <Centered>
            <NotFoundPage />
          </Centered>
        ),
      },
    ],
  },
  {
    path: RouteUrl.Login,
    element: isLoggedIn ? <Navigate to={RouteUrl.App} /> : <LoginPage />,
  },
  {
    path: `/${RouteUrl.SignUpFlower}`,
    element: isLoggedIn ? <Navigate to={RouteUrl.App} /> : <SignUpFlowerPage />,
  },
  {
    path: `/${RouteUrl.SignUpDistributor}`,
    element: isLoggedIn ? (
      <Navigate to={RouteUrl.App} />
    ) : (
      <SignUpDistributorPage />
    ),
  },
  {
    path: `/${RouteUrl.SignUpInstaller}`,
    element: isLoggedIn ? (
      <Navigate to={RouteUrl.App} />
    ) : (
      <SignUpInstallerPage />
    ),
  },
  {
    path: `/${RouteUrl.SignUpAssetOwner}`, // This should be merged with SIGN_UP_FLOW
    element: isLoggedIn ? <Navigate to={RouteUrl.App} /> : <SignUpFlowPage />,
  },
  {
    path: `/${RouteUrl.ForgotPassword}`,
    element: isLoggedIn ? (
      <Navigate to={RouteUrl.App} />
    ) : (
      <ForgotPasswordPage />
    ),
  },
  {
    path: `/${RouteUrl.ResetPassword}`,
    element: isLoggedIn ? (
      <Navigate to={RouteUrl.App} />
    ) : (
      <ResetPasswordPage />
    ),
  },
  {
    path: `/${RouteUrl.SignUpFlow}`,
    element: isLoggedIn ? <Navigate to={RouteUrl.App} /> : <SignUpFlowPage />,
  },
  {
    path: RouteUrl.Home,
    element: isLoggedIn ? <Navigate to={RouteUrl.App} /> : <LoginPage />,
  },
  {
    path: "*",
    element: (
      <Centered>
        <NotFoundPage />
      </Centered>
    ),
  },
];

export default routes;

const getAppRoutes = (userRole: Role) => {
  if (isFlower(userRole)) {
    return FLOWER_APP_ROUTES;
  } else if (isDistributor(userRole)) {
    return getDistributorAppRoutes(userRole);
  } else if (isInstaller(userRole)) {
    return getInstallerAppRoutes(userRole);
  } else if (isAssetOwner(userRole)) {
    return ASSET_OWNER_APP_ROUTES;
  } else if (isGuest(userRole)) {
    return [];
  } else throw Error("getAppRoutes not implemented for " + userRole);
};

const FLOWER_APP_ROUTES = [
  {
    path: RouteUrl.Dashboard,
    element: (
      <ScrollablePage isWide={true}>
        <DashboardPage />
      </ScrollablePage>
    ),
  },
  {
    path: RouteUrl.Distributors,
    element: <Outlet />,
    children: [
      {
        path: ":distributorId",
        element: <Outlet />,
        children: [
          {
            path: ":installerId",
            element: <Outlet />,
            children: [
              {
                path: ":assetOwnerId",
                element: (
                  <NotScrollablePage isWide={true}>
                    <AssetOwnerPage />
                  </NotScrollablePage>
                ),
              },
              {
                path: "",
                element: (
                  <NotScrollablePage isWide={true}>
                    <InstallerPage />
                  </NotScrollablePage>
                ),
              },
            ],
          },
          {
            path: "",
            element: (
              <NotScrollablePage isWide={true}>
                <DistributorPage />
              </NotScrollablePage>
            ),
          },
        ],
      },
      {
        path: "",
        element: (
          <ScrollablePage isWide={true}>
            <DistributorsPage />
          </ScrollablePage>
        ),
      },
    ],
  },
  {
    path: RouteUrl.Installers,
    element: <Outlet />,
    children: [
      {
        path: "",
        element: (
          <NotScrollablePage isWide={true} style={"py-4 md:py-8"}>
            <InstallerListPage />
          </NotScrollablePage>
        ),
      },
    ],
  },
  {
    path: RouteUrl.AssetOwners,
    element: (
      <NotScrollablePage isWide={true} style={"py-4 md:py-8"}>
        <AssetOwnerListPage />
      </NotScrollablePage>
    ),
  },
  {
    path: RouteUrl.Assets,
    element: (
      <NotScrollablePage isWide={true} style={"py-4 md:py-8"}>
        <AssetListPage />
      </NotScrollablePage>
    ),
  },
  {
    path: RouteUrl.Organization,
    element: (
      <NotScrollablePage style={"py-4 md:py-8"}>
        <FlowerOrganizationPage />
      </NotScrollablePage>
    ),
  },
  {
    path: RouteUrl.Settings,
    element: (
      <NotScrollablePage isWide={true} style={"py-4 md:py-8"}>
        <FlowerSettingsPage />
      </NotScrollablePage>
    ),
  },
  {
    path: RouteUrl.ManualAction,
    element: (
      <NotScrollablePage isWide={true} style={"py-4 md:py-8"}>
        <ManualActionPage />
      </NotScrollablePage>
    ),
  },
  {
    path: RouteUrl.Compensation,
    element: (
      <NotScrollablePage isWide={true} style={"py-4 md:py-8"}>
        <CompensationPage />
      </NotScrollablePage>
    ),
  },
  {
    path: "",
    element: <Navigate to={`${RouteUrl.App}/${RouteUrl.Dashboard}`} />,
  },
];

const getDistributorAppRoutes = (loggedInUserRole: Role) =>
  DISTRIBUTOR_APP_ROUTES.filter(
    (item) => loggedInUserRole >= (item.minRole ?? Role.DistributorUser),
  ).map((item) => {
    const { minRole, ...route } = item;
    return route;
  });

const DISTRIBUTOR_APP_ROUTES = [
  {
    path: RouteUrl.Dashboard,
    element: (
      <ScrollablePage isWide={true}>
        <DashboardPage />
      </ScrollablePage>
    ),
    minRole: Role.DistributorUser,
  },
  {
    path: RouteUrl.Installers,
    element: <Outlet />,
    children: [
      {
        path: ":installerId",
        element: <Outlet />,
        children: [
          {
            path: ":assetOwnerId",
            element: (
              <NotScrollablePage isWide={true}>
                <AssetOwnerPage />
              </NotScrollablePage>
            ),
          },
          {
            path: "",
            element: (
              <NotScrollablePage isWide={true}>
                <InstallerPage />
              </NotScrollablePage>
            ),
          },
        ],
      },
      {
        path: "",
        element: (
          <NotScrollablePage isWide={true} style={"py-4 md:py-8"}>
            <InstallerListPage />
          </NotScrollablePage>
        ),
      },
    ],
    minRole: Role.DistributorUser,
  },
  {
    path: RouteUrl.AssetOwners,
    element: <Outlet />,
    children: [
      {
        path: "",
        element: (
          <NotScrollablePage isWide={true} style={"py-4 md:py-8"}>
            <AssetOwnerListPage />
          </NotScrollablePage>
        ),
      },
    ],
    minRole: Role.DistributorUser,
  },
  {
    path: RouteUrl.Organization,
    element: (
      <NotScrollablePage isWide={true} style={"py-4 md:py-8"}>
        <DistributorOrganizationPage />
      </NotScrollablePage>
    ),
    minRole: Role.DistributorAdmin,
  },
  {
    path: "",
    element: <Navigate to={`${RouteUrl.App}/${RouteUrl.Dashboard}`} />,
  },
];

const getInstallerAppRoutes = (loggedInUserRole: Role) =>
  INSTALLER_APP_ROUTES.filter(
    (item) => loggedInUserRole >= (item.minRole ?? Role.InstallerUser),
  ).map((item) => {
    const { minRole, ...route } = item;
    return route;
  });

const INSTALLER_APP_ROUTES = [
  {
    path: RouteUrl.Dashboard,
    element: (
      <ScrollablePage isWide={true}>
        <DashboardPage />
      </ScrollablePage>
    ),
    minRole: Role.InstallerUser,
  },
  {
    path: RouteUrl.AssetOwners,
    element: <Outlet />,
    children: [
      {
        path: ":assetOwnerId",
        element: (
          <NotScrollablePage isWide={true}>
            <AssetOwnerPage />
          </NotScrollablePage>
        ),
      },
      {
        path: "",
        element: (
          <NotScrollablePage isWide={true} style={"py-4 md:py-8"}>
            <AssetOwnerListPage />
          </NotScrollablePage>
        ),
      },
    ],
    minRole: Role.InstallerUser,
  },
  {
    path: RouteUrl.Organization,
    element: (
      <NotScrollablePage isWide={true} style={"py-4 md:py-8"}>
        <InstallerOrganizationPage />
      </NotScrollablePage>
    ),
    minRole: Role.InstallerAdmin,
  },
  {
    path: "",
    element: <Navigate to={`${RouteUrl.App}/${RouteUrl.Dashboard}`} />,
    minRole: Role.InstallerUser,
  },
];

const ASSET_OWNER_APP_ROUTES = [
  {
    path: RouteUrl.Dashboard,
    element: (
      <ScrollablePage isWide={true}>
        <AssetOwnerDashboardPage />
      </ScrollablePage>
    ),
  },
  {
    path: RouteUrl.InvoiceList,
    element: (
      <ScrollablePage>
        <InvoicesPage />
      </ScrollablePage>
    ),
  },
  {
    path: RouteUrl.Battery,
    element: (
      <ScrollablePage>
        <BatteryPage />
      </ScrollablePage>
    ),
  },
  {
    path: RouteUrl.Profile,
    element: (
      <ScrollablePage>
        <ProfilePage />
      </ScrollablePage>
    ),
  },
  {
    path: RouteUrl.ConsumptionAndRevenue,
    element: (
      <NotScrollablePage style={"py-4 md:py-8"}>
        <ConsumptionAndRevenuePage />
      </NotScrollablePage>
    ),
  },
  {
    path: "",
    element: <Navigate to={`${RouteUrl.App}/${RouteUrl.Dashboard}`} />,
  },
];
