import { useTranslation } from "react-i18next";
import { BanknotesIcon } from "@heroicons/react/24/outline";
import { useDistributorCompensation } from "../../api/compensation/compensation.endpoints";
import CustomPlaceholder from "../CustomPlaceholder";
import {
  getCurrentYearCompensation,
  getPreviousYearCompensation,
  getTotalCompensation,
} from "./CompensationStatistics.helpers";
import React, { FC, useMemo } from "react";
import { CompensationStatistics, Tile } from "./CompensationStatistics";

interface Props {
  distributorId?: number;
}

export const DistributorCompensation: FC<Props> = ({ distributorId }) => {
  const { t } = useTranslation();

  // Get distributor compensation
  const {
    data: distributorCompensation,
    isLoading,
    isError,
  } = useDistributorCompensation(distributorId);

  const currentCompensationTiles: Tile[] = useMemo(
    () =>
      distributorCompensation
        ? [
            {
              title: t("compensation.Total"),
              value: `${getTotalCompensation(distributorCompensation)} SEK`,
            },
            {
              title: t("compensation.PreviousYear"),
              value: `${getPreviousYearCompensation(distributorCompensation)} SEK`,
            },
            {
              title: t("compensation.CurrentYear"),
              value: `${getCurrentYearCompensation(distributorCompensation)} SEK`,
            },
          ]
        : [],
    [distributorCompensation],
  );

  if (isLoading || isError) {
    return (
      <CustomPlaceholder
        isLoading={isLoading}
        isError={isError}
        isEmpty={false}
        icon={<BanknotesIcon />}
        errorMessage={t(`compensation.UnableToLoad`)}
        emptyDataMessage={t(`compensation.NotFound`)}
        loaderSize="page"
      />
    );
  }

  return (
    <CompensationStatistics
      title={t("compensation.Compensation")}
      icon={<BanknotesIcon />}
      tiles={currentCompensationTiles}
      emptyDataMessage={t("compensation.NotFound")}
    />
  );
};
