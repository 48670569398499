import { BillingAdjustmentStatistics } from "components/billingAdjustment/BillingAdjustmentStatistics";
import { currentInvoicedMonth } from "./BillingAdjustment.helper";
import { QualifiedAssetOwnerList } from "components/billingAdjustment/QualifiedAssetOwnerList";

export default function BillingAdjustmentTab() {
  return (
    <div className="overflow-auto">
      <div className="bg-gradient-to-r from-slate-50 flex items-center justify-between gap-x-6 px-6 py-2.5 sm:pr-3.5 lg:pl-8 ">
        <p className="text-base font-semibold text-flowerGray-800">
          {currentInvoicedMonth}
        </p>
      </div>
      <BillingAdjustmentStatistics />
      <QualifiedAssetOwnerList />
    </div>
  );
}
