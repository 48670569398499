import { useTranslation } from "react-i18next";
import { BanknotesIcon } from "@heroicons/react/24/outline";
import { useInstallerCompensation } from "../../api/compensation/compensation.endpoints";
import CustomPlaceholder from "../CustomPlaceholder";
import {
  getCurrentYearCompensation,
  getPreviousYearCompensation,
  getTotalCompensation,
} from "./CompensationStatistics.helpers";
import React, { FC, useMemo } from "react";
import { CompensationStatistics, Tile } from "./CompensationStatistics";

interface Props {
  installerId?: number;
}

export const InstallerCompensation: FC<Props> = ({ installerId }) => {
  const { t } = useTranslation();

  // Get installer compensation
  const {
    data: installerCompensation,
    isLoading,
    isError,
  } = useInstallerCompensation(installerId);

  const currentCompensationTiles: Tile[] = useMemo(
    () =>
      installerCompensation
        ? [
            {
              title: t("compensation.Total"),
              value: `${getTotalCompensation(installerCompensation)} SEK`,
            },
            {
              title: t("compensation.PreviousYear"),
              value: `${getPreviousYearCompensation(installerCompensation)} SEK`,
            },
            {
              title: t("compensation.CurrentYear"),
              value: `${getCurrentYearCompensation(installerCompensation)} SEK`,
            },
          ]
        : [],
    [installerCompensation],
  );

  if (isLoading || isError) {
    return (
      <CustomPlaceholder
        isLoading={isLoading}
        isError={isError}
        isEmpty={false}
        icon={<BanknotesIcon />}
        errorMessage={t(`compensation.UnableToLoad`)}
        emptyDataMessage={t(`compensation.NotFound`)}
        loaderSize="page"
      />
    );
  }

  return (
    <CompensationStatistics
      title={t("compensation.Compensation")}
      icon={<BanknotesIcon />}
      tiles={currentCompensationTiles}
      emptyDataMessage={t("compensation.NotFound")}
    />
  );
};
