import { FC, Fragment } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Checkbox } from "components/input/Checkbox";
import { useForm } from "react-hook-form";
import { installationGuide } from "constants/externalLinks";
import { cloudFrontDomainUrl } from "constants/environment";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import SecondaryButton from "components/buttons/SecondaryButton";

type Props = {
  isOpen: boolean;
  startTheInstallationSteps: () => void;
  onClose: () => void;
};

export const BeforeYouStartStepModal: FC<Props> = ({
  isOpen,
  startTheInstallationSteps,
  onClose,
}) => {
  const { t } = useTranslation();
  const { register, watch } = useForm<{ isRead: boolean }>({
    defaultValues: {
      isRead: false,
    },
  });

  const isRead = watch("isRead");

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        {/* Background Overlay */}
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
        </Transition.Child>

        {/* Modal Content */}
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg shadow-xl transform transition-all sm:my-8 w-full max-w-2xl p-6">
                {/* Close Button */}
                <button
                  type="button"
                  className="absolute top-4 right-4 rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-flowerSky-500"
                  onClick={onClose}
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>

                {/* Modal Body */}
                <div>
                  <div className="flex justify-center mt-4">
                    <img
                      src={`${cloudFrontDomainUrl}/installation-flow/installation-guide-scroll.gif`}
                      alt="installation guide setup"
                      className="w-full lg:w-2/5"
                    />
                  </div>
                  {/* Modal Title */}
                  <Dialog.Title
                    as="h2"
                    className="text-2xl font-medium text-gray-900 mt-8 mb-4"
                  >
                    {t("batteryOverview.Before you start")}
                  </Dialog.Title>

                  <div className="flex justify-center">
                    <div className="text-center lg:text-left text-sm w-9/12 font-normal text-gray-800">
                      <Trans
                        i18nKey={"batteryOverview.installationGuideReadCheck"}
                        components={{
                          InstallationGuideLink: (
                            <a
                              target="_blank"
                              rel="noreferrer noopener"
                              href={installationGuide}
                              className="underline text-blue-600 hover:text-blue-800"
                            />
                          ),
                        }}
                      />
                      <div className="mt-4 text-left">
                        <Checkbox
                          register={register}
                          item={{
                            label: t(
                              "batteryOverview.I have completed all the prerequisite steps in the installation guide",
                            ),
                            name: "isRead",
                          }}
                          textSize="text-sm"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Modal Actions */}
                <div className="mt-8 flex justify-end">
                  <SecondaryButton
                    size="small"
                    onClick={startTheInstallationSteps}
                    disabled={!isRead}
                  >
                    {t("Get started")}
                  </SecondaryButton>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
