import { TabPageWrapper } from "components/tab/TabPageWrapper";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { RouteUrl } from "routing/routeUrl.enum";
import { RouteLabel } from "routing/routeLabel.enum";
import { BreadCrumb, menuStore } from "store/menu.store";

import { AssetModelList } from "../../../components/assetModel/AssetModelList";
import { PrequalificationGroups } from "../../../components/prequalificationGroup/PrequalificationGroups";
import { SystemNotificationList } from "../../../components/systemNotification/SystemNotificationList";
import { useTranslation } from "react-i18next";
import TabContainer from "../../../components/tab/TabContainer";

const PREQUALIFICATION_GROUP_TAB = "prequalification-groups";
const ASSET_MODELS_TAB = "asset-models";
const SYSTEM_NOTIFICATION_TAB = "system-notification";

export const FlowerSettingsPage = () => {
  const { t } = useTranslation();

  // Tab
  const [searchParams, setSearchParams] = useSearchParams();
  const setTab = (tab: string) => setSearchParams({ tab: tab.toLowerCase() });

  // Breadcrumbs
  const { setBreadCrumbs } = menuStore();
  useEffect(() => {
    setBreadCrumbs(getBreadCrumbs());
  }, []);

  const currentTab = searchParams.get("tab") || PREQUALIFICATION_GROUP_TAB;

  return (
    <TabPageWrapper
      tabs={[
        {
          param: PREQUALIFICATION_GROUP_TAB,
          label: t(`prequalificationGroup.Title`),
          content: (
            <TabContainer key={PREQUALIFICATION_GROUP_TAB}>
              <PrequalificationGroups />
            </TabContainer>
          ),
          isSelected: currentTab === PREQUALIFICATION_GROUP_TAB,
        },
        {
          param: ASSET_MODELS_TAB,
          label: t(`assetModel.Title`),
          content: (
            <TabContainer key={ASSET_MODELS_TAB}>
              <AssetModelList />
            </TabContainer>
          ),
          isSelected: currentTab === ASSET_MODELS_TAB,
        },
        {
          param: SYSTEM_NOTIFICATION_TAB,
          label: t(`systemNotification.Title`),
          content: (
            <TabContainer key={SYSTEM_NOTIFICATION_TAB}>
              <SystemNotificationList />
            </TabContainer>
          ),
          isSelected: currentTab === SYSTEM_NOTIFICATION_TAB,
        },
      ]}
      setTab={setTab}
    />
  );
};

const getBreadCrumbs = (): BreadCrumb[] => [
  {
    label: RouteLabel.Settings,
    to: `${RouteUrl.App}/${RouteUrl.Settings}`,
  },
];
