import {
  BuildingLibraryIcon,
  CheckBadgeIcon,
  ClipboardDocumentListIcon,
  HomeModernIcon,
  PaperAirplaneIcon,
} from "@heroicons/react/24/outline";
import { useBillingAdjustmentStatistics } from "api/bessCompensation/bessCompensation.endpoints";
import CustomPlaceholder from "components/CustomPlaceholder";
import DashboardTile from "components/statistics/DashboardTile";
import KeyValueTile from "components/statistics/KeyValueTile";
import { FC } from "react";
import { useTranslation } from "react-i18next";

export const BillingAdjustmentStatistics: FC = () => {
  const { t } = useTranslation();

  const {
    data: statisticsData,
    isLoading: isLoadingStatistics,
    isError: statisticsIsErrored,
  } = useBillingAdjustmentStatistics();

  if (isLoadingStatistics || !statisticsData || statisticsIsErrored) {
    return (
      <div className="my-8">
        <CustomPlaceholder
          isError={statisticsIsErrored}
          isLoading={isLoadingStatistics}
          isEmpty={
            !isLoadingStatistics && !statisticsIsErrored && !statisticsData
          }
          icon={<ClipboardDocumentListIcon />}
          errorMessage={t(`dashboardStatistics.UnableToLoad`)}
          emptyDataMessage={t(`dashboardStatistics.NotFound`)}
          loaderSize="page"
        />
      </div>
    );
  }

  return (
    <div className="flow-root w-full mb-6">
      <dl className="flex flex-wrap gap-4 my-2 justify-center md:justify-start">
        <DashboardTile
          title={t("billingAdjustment.TotalAssetOwners")}
          leftStat={{
            count: statisticsData.totalAssetOwners,
          }}
          showDeviation={false}
          icon={<HomeModernIcon />}
        />
        <DashboardTile
          title={t("billingAdjustment.QualifiedAssetOwners")}
          leftStat={{
            count: statisticsData.qualifiedAssetOwnersCount,
          }}
          showDeviation={false}
          icon={<CheckBadgeIcon />}
        />
        <DashboardTile
          title={t("billingAdjustment.RegisteredBankAccount")}
          leftStat={{
            count: statisticsData.bankAccountRegisteredAssetOwnersCount,
          }}
          icon={<BuildingLibraryIcon />}
          showDeviation={false}
        />
        <DashboardTile
          title={t("billingAdjustment.BillingAdjustmentPosted")}
          leftStat={{
            count: statisticsData.billingAdjustmentPostedAssetOwnerCount,
          }}
          icon={<PaperAirplaneIcon />}
          showDeviation={false}
        />
      </dl>
      <dl className="mt-4 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-3 shadow">
        <KeyValueTile
          keyword={t("billingAdjustment.QualifiedTotal")}
          value={`${statisticsData.qualifiedTotalAmount} SEK`}
        />
        <KeyValueTile
          keyword={t("billingAdjustment.PayoutTotal")}
          value={`${statisticsData.payoutTotal} SEK`}
        />
        <KeyValueTile
          keyword={t("billingAdjustment.BillingAdjustmentPostedTotal")}
          value={`${statisticsData.billingAdjustmentPostedTotal} SEK`}
        />
      </dl>
    </div>
  );
};
