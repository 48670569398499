import { FC, useState } from "react";
import { FlowerHubLogs } from "./flowerHubLogs/FlowerHubLogs";
import { SystemManagerLogs } from "./flowerHubSystemManager/SystemManagerLogs";
import { useTranslation } from "react-i18next";
import CustomPlaceholder from "components/CustomPlaceholder";
import userStore, { getUserRole } from "store/user.store";
import { FlowerHubSystemManager } from "./flowerHubSystemManager/FlowerHubSystemManager";
import { FlowerHubStatisticsFlowerVersion } from "./flowerHubStatistics/FlowerHubStatisticsFlowerVersion";
import { FlowerHubComponents } from "components/flowerHub/FlowerHubComponents";
import { InstallFlowerHub } from "components/installationFlow/InstallFlowerhub";
import { isDistributor, isFlower, isInstaller } from "../../helpers/role";
import InstallationProgress from "./installationProgress/InstallationProgress";
import { useFlowerHub } from "api/flowerHub/flowerHub.endpoints";
import OperationalProgress from "./operationalProgress/OperationalProgress";
import InstallationProgressFlowerVersion from "./installationProgress/InstallationProgressFlowerVersion";
import { ServerIcon } from "@heroicons/react/24/outline";
import SecondaryButton from "components/buttons/SecondaryButton";
import FlowerHubIcon from "styles/icons/FlowerHubIcon";
import { classNames } from "helpers/classNames";

interface Props {
  assetOwnerId: number;
  assetId?: number;
  serialNumber?: string;
}

export const FlowerHubDetails: FC<Props> = ({
  assetOwnerId,
  assetId,
  serialNumber,
}) => {
  const { t } = useTranslation();
  const userRole = userStore(getUserRole);
  const { data, isError, isLoading } = useFlowerHub(serialNumber);
  // State to toggle InstallFlowerHub steps visibility
  const [showInstall, setShowInstall] = useState(false);

  // Asset data
  if (!assetId || !serialNumber) {
    return (
      <>
        {isInstaller(userRole) ? (
          <div className="overflow-auto mb-4">
            {!showInstall ? (
              <div className="my-8 flex justify-center items-center">
                <div className="flex flex-col items-center">
                  <SecondaryButton
                    size="small"
                    onClick={() => setShowInstall(true)}
                  >
                    <span
                      className={classNames(
                        `bg-flowerPeach-50`,
                        `text-flowerPeach-700`,
                        "inline-flex rounded-lg p-1 mr-1 hover:bg-flowerPeach-900 hover:text-white",
                      )}
                    >
                      <FlowerHubIcon className="w-5 h-5" />
                    </span>

                    {t(`batteryOverview.Install Flower Hub`)}
                  </SecondaryButton>
                  <h3 className="mt-4 text-sm font-medium text-gray-600 text-center">
                    {t(`batteryOverview.Install Flower Hub start text`)}
                  </h3>
                </div>
              </div>
            ) : (
              <InstallFlowerHub
                assetOwnerId={assetOwnerId}
                closeInstallationSteps={() => setShowInstall(false)}
              />
            )}
          </div>
        ) : (
          <div className="my-8">
            <CustomPlaceholder
              isError={false}
              isLoading={false}
              isEmpty={true}
              icon={<ServerIcon />}
              errorMessage=""
              emptyDataMessage={t(`flowerHub.NoFlowerHub`)}
            />
          </div>
        )}
      </>
    );
  }

  // Flower Hub data
  if (isLoading || !data || isError) {
    return (
      <div className="my-8">
        <CustomPlaceholder
          isError={isError}
          isLoading={isLoading}
          isEmpty={!isLoading && !isError && !data}
          icon={<ServerIcon />}
          errorMessage={t(`flowerHubProgress.UnableToLoad`)}
        />
      </div>
    );
  }

  return (
    <div className="overflow-auto">
      {/* Installer View */}
      {isInstaller(userRole) &&
        (data.operationalStatus ? (
          <OperationalProgress
            operationalStatus={data.operationalStatus}
            userRole={userRole}
            serialNumber={serialNumber}
          />
        ) : (
          <InstallationProgress
            serialNumber={serialNumber}
            installationStatus={data.installationStatus}
          />
        ))}

      {/* Distributor View */}
      {isDistributor(userRole) &&
        (data.operationalStatus ? (
          <OperationalProgress
            operationalStatus={data.operationalStatus}
            userRole={userRole}
            serialNumber={serialNumber}
          />
        ) : (
          <InstallationProgressFlowerVersion flowerHub={data} isDistributor />
        ))}

      {/* Flower user View */}
      {isFlower(userRole) && (
        <>
          <FlowerHubStatisticsFlowerVersion assetId={assetId} />
          <InstallationProgressFlowerVersion flowerHub={data} />
          {data.operationalStatus && (
            <OperationalProgress
              operationalStatus={data.operationalStatus}
              userRole={userRole}
              serialNumber={serialNumber}
            />
          )}
          <FlowerHubComponents serialNumber={serialNumber} />
          <FlowerHubSystemManager assetId={assetId} />
          <SystemManagerLogs assetId={assetId} />
        </>
      )}

      {/* Common View */}
      {data.firstDeploymentCompletedAt && (
        <FlowerHubLogs assetId={assetId} serialNumber={serialNumber} />
      )}
    </div>
  );
};
