import {
  BanknotesIcon,
  Battery0Icon,
  BoltIcon,
  ChartBarIcon,
  CogIcon,
  CubeIcon,
  DocumentDuplicateIcon,
  HandRaisedIcon,
  HomeModernIcon,
  ServerStackIcon,
  TruckIcon,
  UserCircleIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { BreadCrumbIconType } from "store/menu.store";
import { RouteUrl } from "routing/routeUrl.enum";
import { Role } from "enums/role.enum";
import { RouteLabel } from "routing/routeLabel.enum";
import { getAssetListLink } from "pages/app/assetList/AssetListPage";
import { ForwardRefExoticComponent, RefAttributes, SVGProps } from "react";

export const getBreadCrumbIcon = (type: BreadCrumbIconType) => {
  switch (type) {
    case "assetOwner":
      return (
        <HomeModernIcon
          className="flex-shrink-0 h-4 w-4 mr-1 text-gray-400"
          aria-hidden="true"
        />
      );
    case "distributor":
      return (
        <CubeIcon
          className="flex-shrink-0 h-4 w-4 mr-1 text-gray-400"
          aria-hidden="true"
        />
      );
    case "installer":
      return (
        <TruckIcon
          className="flex-shrink-0 h-4 w-4 mr-1 text-gray-400"
          aria-hidden="true"
        />
      );
    default:
      return null;
  }
};

export const navigation: NavigationItem[] = [
  // Property "name" is a key for translation (navigation.name). Update json files if you change it
  {
    name: RouteLabel.Dashboard,
    href: `${RouteUrl.App}/${RouteUrl.Dashboard}`,
    allowedRoles: [
      Role.InstallerAdmin,
      Role.InstallerUser,
      Role.AssetOwner,
      Role.DistributorAdmin,
      Role.DistributorUser,
      Role.Flower,
    ],
    icon: BoltIcon,
  },
  {
    name: RouteLabel.Battery,
    href: `${RouteUrl.App}/${RouteUrl.Battery}`,
    allowedRoles: [Role.AssetOwner],
    icon: Battery0Icon,
  },
  {
    name: RouteLabel.ConsumptionAndRevenue,
    href: `${RouteUrl.App}/${RouteUrl.ConsumptionAndRevenue}`,
    allowedRoles: [Role.AssetOwner],
    icon: ChartBarIcon,
  },
  {
    name: RouteLabel.Distributors,
    href: `${RouteUrl.App}/${RouteUrl.Distributors}`,
    allowedRoles: [Role.Flower],
    icon: CubeIcon,
  },
  {
    name: RouteLabel.Installers,
    href: `${RouteUrl.App}/${RouteUrl.Installers}`,
    allowedRoles: [Role.Flower, Role.DistributorAdmin, Role.DistributorUser],
    icon: TruckIcon,
  },
  {
    name: RouteLabel.AssetOwners,
    href: `${RouteUrl.App}/${RouteUrl.AssetOwners}`,
    allowedRoles: [
      Role.Flower,
      Role.DistributorAdmin,
      Role.DistributorUser,
      Role.InstallerAdmin,
      Role.InstallerUser,
    ],
    icon: HomeModernIcon,
  },
  {
    name: RouteLabel.Assets,
    href: getAssetListLink(),
    allowedRoles: [Role.Flower],
    icon: ServerStackIcon,
  },
  {
    name: RouteLabel.Invoices,
    href: `${RouteUrl.App}/${RouteUrl.InvoiceList}`,
    allowedRoles: [Role.AssetOwner],
    icon: DocumentDuplicateIcon,
  },
  {
    name: RouteLabel.Organization,
    href: `${RouteUrl.App}/${RouteUrl.Organization}`,
    allowedRoles: [Role.Flower, Role.DistributorAdmin, Role.InstallerAdmin],
    icon: UsersIcon,
  },
  {
    name: RouteLabel.Settings,
    href: `${RouteUrl.App}/${RouteUrl.Settings}`,
    allowedRoles: [Role.Flower],
    icon: CogIcon,
  },
  {
    name: RouteLabel.Profile,
    href: `${RouteUrl.App}/${RouteUrl.Profile}`,
    allowedRoles: [Role.AssetOwner],
    icon: UserCircleIcon,
  },
  {
    name: RouteLabel.Compensation,
    href: `${RouteUrl.App}/${RouteUrl.Compensation}`,
    allowedRoles: [Role.Flower],
    icon: BanknotesIcon,
  },
  {
    name: RouteLabel.ManualAction,
    href: `${RouteUrl.App}/${RouteUrl.ManualAction}`,
    allowedRoles: [Role.Flower],
    icon: HandRaisedIcon,
  },
];

type IconSVGProps = ForwardRefExoticComponent<
  Omit<SVGProps<SVGSVGElement>, "ref"> & {
    title?: string | undefined;
    titleId?: string | undefined;
  } & RefAttributes<SVGSVGElement>
>;

export interface NavigationItem {
  name: string;
  href: string;
  isCurrentRoute?: boolean;
  allowedRoles: Role[];
  icon: IconSVGProps;
}
