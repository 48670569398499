import { cloneElement, FC, ReactNode } from "react";
import { CheckIcon } from "@heroicons/react/24/outline";
import { AssetStep } from "../installFlowerHub.helpers";
import { classNames } from "helpers/classNames";
import FlowerHubIcon, {
  CustomSvgComponentProps,
} from "styles/icons/FlowerHubIcon";
import { StepStatus } from "enums/flowerHubStatus.enum";
import BoltIcon from "styles/icons/BoltIcon";
import HomeIcon from "styles/icons/HomeIcon";
import ConfirmedDocIcon from "styles/icons/ConfirmedDocIcon";
import ConfirmedIcon from "styles/icons/ConfirmedIcon";

const steps: StepProp[] = [
  { name: AssetStep.CONNECT_ETH, icon: <ConfirmedIcon /> },
  { name: AssetStep.ASSET_INFORMATION, icon: <HomeIcon /> },
  { name: AssetStep.SERIAL_NUMBER, icon: <FlowerHubIcon /> },
  { name: AssetStep.META_DATA_CONFIRMATION, icon: <ConfirmedDocIcon /> },
  { name: AssetStep.POWER_CONFIRMATION, icon: <BoltIcon /> },
];

type InstallationStepProgressProps = {
  currentStep: AssetStep;
};

const getStepStatus = (
  stepName: AssetStep,
  currentStep: AssetStep,
): StepStatus => {
  const stepIndex = steps.findIndex((s) => s.name === stepName);
  const currentStepIndex = steps.findIndex((s) => s.name === currentStep);

  if (stepIndex < currentStepIndex) return StepStatus.Complete;
  if (stepIndex === currentStepIndex) return StepStatus.Current;
  return StepStatus.Upcoming;
};

const InstallationStepProgress: FC<InstallationStepProgressProps> = ({
  currentStep,
}) => {
  return (
    <nav className="flex w-full justify-center mb-8">
      <ol role="list" className="flex items-center">
        {steps.map((step, stepIdx) => {
          const status = getStepStatus(step.name, currentStep);
          const isLastStep = stepIdx === steps.length - 1;

          return (
            <li
              key={step.name}
              className={classNames(
                !isLastStep ? "pr-4 sm:pr-20" : "",
                "relative",
              )}
            >
              <StepIcon status={status} step={step} />
              {!isLastStep && (
                <div
                  aria-hidden="true"
                  className="absolute inset-0 flex items-center"
                >
                  <div
                    className={classNames(
                      status === StepStatus.Complete
                        ? "bg-flowerSkyVivid-500"
                        : "bg-gray-200",
                      "h-0.5 w-full",
                    )}
                  />
                </div>
              )}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

type StepProp = {
  name: AssetStep;
  icon: ReactNode;
};

type StepIconProps = {
  status: StepStatus;
  step: StepProp;
};

const StepIcon: FC<StepIconProps> = ({ status, step }) => {
  switch (status) {
    case StepStatus.Complete:
      return (
        <div className="relative flex h-12 w-12 p-3 items-center justify-center rounded-full bg-flowerSkyVivid-500 z-10">
          <CheckIcon className="h-8 w-8 text-white" aria-hidden="true" />
          <span className="sr-only">{step.name}</span>
        </div>
      );

    case StepStatus.Current:
      return (
        <div
          aria-current="step"
          className="relative flex h-12 w-12 p-3 items-center justify-center rounded-full bg-flowerSky-50 z-10"
        >
          {cloneElement(
            step.icon as React.ReactElement<CustomSvgComponentProps>,
            {
              color: "#4467FE",
              className: "text-flowerSkyVivid-500",
            },
          )}
          <span className="sr-only">{step.name}</span>
        </div>
      );

    case StepStatus.Upcoming:
      return (
        <div className="relative flex h-12 w-12 p-3 items-center justify-center rounded-full bg-gray-100 z-10">
          {step.icon}
          <span className="sr-only">{step.name}</span>
        </div>
      );

    default:
      return null;
  }
};

export default InstallationStepProgress;
