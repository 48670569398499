import { GetCompensationResponseDTO } from "../../api/compensation/compensation.dto";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  getCurrentYearCompensation,
  getPreviousMonthCompensation,
  getPreviousYearCompensation,
  getTotalCompensation,
} from "./CompensationStatistics.helpers";
import { BanknotesIcon } from "@heroicons/react/24/outline";
import { CompensationStatistics, Tile } from "./CompensationStatistics";

interface Props {
  compensation: GetCompensationResponseDTO[] | undefined;
}

export const Compensation: FC<Props> = ({ compensation }) => {
  const { t } = useTranslation();

  const compensationTiles: Tile[] = useMemo(
    () =>
      compensation
        ? [
            {
              title: t("compensation.Total"),
              value: `${getTotalCompensation(compensation)} SEK`,
            },
            {
              title: t("compensation.PreviousYear"),
              value: `${getPreviousYearCompensation(compensation)} SEK`,
            },
            {
              title: t("compensation.CurrentYear"),
              value: `${getCurrentYearCompensation(compensation)} SEK`,
            },
            {
              title: t("compensation.PreviousMonth"),
              value: `${getPreviousMonthCompensation(compensation)} SEK`,
            },
          ]
        : [],
    [compensation],
  );

  return (
    <div className="overflow-auto">
      <CompensationStatistics
        title={t("compensation.CompensationStatistics")}
        icon={<BanknotesIcon />}
        tiles={compensationTiles}
        emptyDataMessage={t("compensation.NotFound")}
      />
    </div>
  );
};
