import { FC } from "react";
import { LightBulbIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { ActionButton } from "../../buttons/ActionButton";
import { cloudFrontDomainUrl } from "constants/environment";
import { getDeviceForMedia } from "helpers/installationFlow";

type Props = {
  changeStep: (step: number) => void;
  onCancel: () => void;
};

export const ConnectedEthStep: FC<Props> = ({ changeStep, onCancel }) => {
  const { t } = useTranslation();

  return (
    <div className="space-y-8">
      <span className="text-4xl font-normal leading-[105%]">
        {t("Let's install the Flower Hub")}
      </span>
      <div className="flex justify-center">
        <img
          src={`${cloudFrontDomainUrl}/installation-flow/${getDeviceForMedia()}/initial-setup.jpg`}
          alt="Initial setup"
          className="w-full lg:w-2/3"
        />
      </div>

      <div className="flex justify-center">
        <div className="text-base w-9/12 font-normal text-gray-600">
          <ul className="list-disc pl-8 text-base text-gray-800 pt-2">
            <span className="flex mb-4 text-base text-gray-800">
              <LightBulbIcon className="h-5 w-5 mr-2" aria-hidden="true" />
              {t(
                `batteryOverview.Do not plug in the power cable yet We will tell you when to do so`,
              )}
            </span>
            <span className="flex mb-4 text-base text-gray-800">
              <LightBulbIcon className="h-5 w-5 mr-2" aria-hidden="true" />
              {t(
                "batteryOverview.Connect the Flower Hub with an Ethernet cable to the same router as the inverter",
              )}
            </span>
          </ul>
        </div>
      </div>
      <div className="flex justify-between">
        <ActionButton
          onClick={() => onCancel()}
          label={t("Cancel")}
          variant="black"
        />
        <ActionButton
          onClick={() => changeStep(2)}
          label={t("Next")}
          variant="primary"
        />
      </div>
    </div>
  );
};
