import {
  useDistributorsCompensation,
  useInstallersCompensation,
  useTotalCompensation,
} from "../../api/compensation/compensation.endpoints";
import {
  getCurrentYearCompensation,
  getPreviousMonthCompensation,
  getPreviousYearCompensation,
  getTotalCompensation,
} from "./CompensationStatistics.helpers";
import CustomPlaceholder from "../CustomPlaceholder";
import {
  ClipboardDocumentListIcon,
  CubeIcon,
  HomeModernIcon,
  TruckIcon,
} from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { CompensationStatistics, Tile } from "./CompensationStatistics";
import React, { useMemo } from "react";

export const CompensationStatisticsTab = () => {
  const { t } = useTranslation();

  const {
    data: totalCompensation,
    isLoading: isLoadingTotalCompensation,
    isError: isErrorTotalCompensation,
  } = useTotalCompensation();

  const {
    data: distributorsCompensation,
    isLoading: isLoadingDistributorsCompensation,
    isError: isErrorDistributorsCompensation,
  } = useDistributorsCompensation();

  const {
    data: installerCompensation,
    isLoading: isLoadingInstallersCompensation,
    isError: isErrorInstallersCompensation,
  } = useInstallersCompensation();

  const allStakeholdersTiles: Tile[] = useMemo(
    () =>
      totalCompensation
        ? [
            {
              title: t("compensation.Total"),
              value: `${getTotalCompensation(totalCompensation)} SEK`,
            },
            {
              title: t("compensation.PreviousYear"),
              value: `${getPreviousYearCompensation(totalCompensation)} SEK`,
            },
            {
              title: t("compensation.CurrentYear"),
              value: `${getCurrentYearCompensation(totalCompensation)} SEK`,
            },
            {
              title: t("compensation.PreviousMonth"),
              value: `${getPreviousMonthCompensation(totalCompensation)} SEK`,
            },
          ]
        : [],
    [totalCompensation],
  );

  const distributorsTiles: Tile[] = useMemo(
    () =>
      distributorsCompensation
        ? [
            {
              title: t("compensation.Total"),
              value: `${getTotalCompensation(distributorsCompensation)} SEK`,
            },
            {
              title: t("compensation.PreviousYear"),
              value: `${getPreviousYearCompensation(distributorsCompensation)} SEK`,
            },
            {
              title: t("compensation.CurrentYear"),
              value: `${getCurrentYearCompensation(distributorsCompensation)} SEK`,
            },
            {
              title: t("compensation.PreviousMonth"),
              value: `${getPreviousMonthCompensation(distributorsCompensation)} SEK`,
            },
          ]
        : [],
    [distributorsCompensation],
  );

  const installersTiles: Tile[] = useMemo(
    () =>
      installerCompensation
        ? [
            {
              title: t("compensation.Total"),
              value: `${getTotalCompensation(installerCompensation)} SEK`,
            },
            {
              title: t("compensation.PreviousYear"),
              value: `${getPreviousYearCompensation(installerCompensation)} SEK`,
            },
            {
              title: t("compensation.CurrentYear"),
              value: `${getCurrentYearCompensation(installerCompensation)} SEK`,
            },
            {
              title: t("compensation.PreviousMonth"),
              value: `${getPreviousMonthCompensation(installerCompensation)} SEK`,
            },
          ]
        : [],
    [installerCompensation],
  );

  const isLoading =
    isLoadingTotalCompensation ||
    isLoadingDistributorsCompensation ||
    isLoadingInstallersCompensation;

  const isError =
    isErrorTotalCompensation ||
    isErrorDistributorsCompensation ||
    isErrorInstallersCompensation;

  if (isLoading || isError) {
    return (
      <div className="my-8">
        <CustomPlaceholder
          isLoading={isLoading}
          isError={isError}
          isEmpty={false}
          icon={<ClipboardDocumentListIcon />}
          errorMessage={t(`compensation.UnableToLoad`)}
          emptyDataMessage={t(`compensation.NotFound`)}
          loaderSize="page"
        />
      </div>
    );
  }

  return (
    <div className="overflow-auto">
      <div className="mb-4">
        <CompensationStatistics
          title={t("compensation.AllStakeholders")}
          icon={[
            <CubeIcon key="cube" />,
            <TruckIcon key="truck" />,
            <HomeModernIcon key="home" />,
          ]}
          tiles={allStakeholdersTiles}
          emptyDataMessage={t("compensation.NotFound")}
        />
      </div>

      <div className="mb-4">
        <CompensationStatistics
          title={t("compensation.Distributors")}
          icon={<CubeIcon key="cube" />}
          tiles={distributorsTiles}
          emptyDataMessage={t("compensation.NotFound")}
        />
      </div>

      <div>
        <CompensationStatistics
          title={t("compensation.Installers")}
          icon={<TruckIcon key="truck" />}
          tiles={installersTiles}
          emptyDataMessage={t("compensation.NotFound")}
        />
      </div>
    </div>
  );
};
