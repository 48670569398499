import { User } from "api/user/user.type";
import { Role } from "enums/role.enum";
import { create } from "zustand";
import { persist } from "zustand/middleware";

const userStore = create<UserState>()(
  persist(
    (set) => ({
      user: undefined,
      setUser: (user: User) =>
        set((state) => ({
          ...state,
          user,
        })),
      clearUser: () =>
        set((state) => ({
          ...state,
          user: undefined,
        })),
    }),
    {
      name: "user-storage",
    },
  ),
);

export const getUserId = (state: UserState): number | undefined =>
  state.user?.id;

export const getUserRole = (state: UserState) =>
  state?.user?.role ?? Role.Guest;

export const getAssetOwnerId = (state: UserState): number | undefined =>
  state.user?.assetOwnerId ?? undefined;

export const getDistributorId = (state: UserState): number | undefined =>
  state.user?.distributorId;

export const getInstallerId = (state: UserState): number | undefined =>
  state.user?.installerId;

export const getUserName = (state: UserState) => state?.user?.name ?? "";

interface UserState {
  user?: User;
  setUser: (user: User) => void;
  clearUser: () => void;
}

export default userStore;
