import React, { FC } from "react";
import {
  BanknotesIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";

export interface Tile {
  title: string;
  value?: string | number;
  unit?: string;
}

interface Props {
  title: string;
  icon: React.ReactNode | React.ReactNode[];
  tiles: Tile[];
  emptyDataMessage?: string;
  warmingMessage?: string;
}

export const CompensationStatistics: FC<Props> = ({
  title,
  icon,
  tiles,
  emptyDataMessage = "",
  warmingMessage = "",
}: Props) => {
  return (
    <div className="w-full p-4 rounded-2xl flex flex-col bg-white h-max-full min-h-12 shadow">
      <div className="flex justify-between gap-2 items-center">
        <span className="text-xl md:text-lg font-medium text-black drop-shadow">
          {title}
        </span>
        {Array.isArray(icon) ? (
          <div className="flex gap-1">
            {icon.map((iconElement, index) => (
              <div
                key={index}
                className="w-8 h-8 md:w-6 md:h-6 text-flowerPeach-900"
              >
                {iconElement}
              </div>
            ))}
          </div>
        ) : (
          <div className="w-8 h-8 md:w-6 md:h-6 text-flowerPeach-900">
            {icon}
          </div>
        )}
      </div>

      <div className="flex-grow overflow-hidden bg-white w-full">
        <dl
          className={`mt-4 grid grid-cols-1 gap-2 overflow-hidden text-center ${setColumnsNumber(tiles.length)}`}
        >
          {tiles.length ? (
            tiles.map((tile) => (
              <div
                key={tile.title}
                className="flex flex-col bg-white p-4 py-10 md:py-6 rounded-2xl shadow m-1"
              >
                <dt className="text-sm font-base leading-6 text-gray-600">
                  {tile.title}
                </dt>
                <dd className="flex items-baseline gap-1 justify-center order-first text-3xl">
                  <span className="text-3xl md:text-2xl font-medium text-flowerSkyVivid-500">
                    {tile.value}
                  </span>
                  {tile.unit && (
                    <span className="text-lg font-medium text-flowerGray-400">
                      {tile.unit}
                    </span>
                  )}
                </dd>
              </div>
            ))
          ) : (
            <div className="w-full flex flex-col justify-center items-center text-center mb-4 px-2 sm:px:0">
              <div className="flex justify-center items-center w-12 h-12 sm:w-12 sm:h-12 text-gray-400">
                <BanknotesIcon />
              </div>
              {emptyDataMessage && (
                <h3 className="mt-2 text-sm font-medium text-gray-600">
                  {emptyDataMessage}
                </h3>
              )}
            </div>
          )}
        </dl>
      </div>
      {warmingMessage && (
        <div className="flex mt-2 gap-2 md:gap-1 justify-center items-center">
          <div className="flex-shrink-0">
            <ExclamationTriangleIcon
              aria-hidden="true"
              className="h-5 w-5 text-flowerRed-500"
            />
          </div>
          <span className="text-flowerRed-500">{warmingMessage}</span>
        </div>
      )}
    </div>
  );
};

const setColumnsNumber = (tilesNumber: number): string => {
  switch (tilesNumber) {
    case 1:
      return "sm:grid-cols-1 lg:grid-cols-1";
    case 2:
      return "sm:grid-cols-2 lg:grid-cols-2";
    case 3:
      return "sm:grid-cols-2 lg:grid-cols-3";
    case 4:
      return "sm:grid-cols-2 lg:grid-cols-4";
    default:
      return "sm:grid-cols-1 lg:grid-cols-1";
  }
};
