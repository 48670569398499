import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "../Modal";
import { TrashIcon } from "@heroicons/react/24/outline";
import { ModalContentWrapper } from "components/ModalContentWrapper";
import { useDeleteCompensationModelPeriod } from "../../api/compensation/compensation.endpoints";

interface Props {
  compensationModelPeriodId: number;
}

export const DeleteCompensationModel: FC<Props> = ({
  compensationModelPeriodId,
}) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  // api
  const { mutate } = useDeleteCompensationModelPeriod();

  // submit
  const onDeleteCompensationModelPeriod = (compensationModelPeriodId: number) =>
    mutate(compensationModelPeriodId);

  // modal close
  const onCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="w-4 h-5 justify-center flex">
        <button onClick={() => setIsModalOpen(true)}>
          <TrashIcon
            className="h-5 w-5 text-flowerRed-800 hover:text-flowerRed-500"
            aria-hidden="true"
          />
        </button>
      </div>

      <Modal
        title={t("compensationModel.Delete compensation model period")}
        leftButtonAction={onCloseModal}
        leftButtonText={t(`Close`)}
        rightButtonText={t("Delete")}
        rightButtonActionHandler={() =>
          onDeleteCompensationModelPeriod(compensationModelPeriodId)
        }
        isOpen={isModalOpen}
        onClose={onCloseModal}
        loadingText={t("compensationModel.Delete compensation model period")}
      >
        <ModalContentWrapper>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              {t(
                "compensationModel.Are you sure you want to delete this compensation period?",
              )}
            </p>
          </div>
        </ModalContentWrapper>
      </Modal>
    </>
  );
};
